<template>
  <div class="wow slideInLeft" style="width:100%">
    
    <el-card  shadow="hover" border-radius=100px box-shadow="0 0 6px rgba(0, 0, 0, .04)" style="height:650px">
      <el-row>
        <el-col :span="9" style="margin-top:10%;padding-left:12%">
            <p style="color:0d1626;font-weight:500;font-size:44px;line-height:60px">华财企服灵活用工系统</p>
            <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">企业级免费灵活用工SaaS系统</p>
            <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">帮助企业实现任务发布、在线签约、智能审核、薪酬结算，</p>
            <p style="line-height:60px;color:#7d8aa1;font-weight:400;font-size:18px">灵工管理等职能服务</p>
        </el-col>
        <el-col :span="15" style="height:650px;padding-left:10%">
           <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_banner.png" style="height:70%;margin-top:5%;"/>
        </el-col>
      </el-row>
    </el-card>
        <div class="block main marginTop wow slideInLeft">
      <p style="text-align: center;color: #2c3e50;font-weight：700;font-size:30px;margin-top:20px">降本增效一站式解决行业难题</p>
      <el-row style="margin-top:50px;z-index:15">
        <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail1.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>全民营销（佣金）</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail2.jpg" style="width:100%"/>
            <div style="padding: 14px;text-align:center">
              <span>技术开发（兼职开发、测试）</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail3.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>设计服务</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail4.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>装卸搬运</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
       <el-row style="margin-top:50px;z-index:15">
        <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail5.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>社交电商（推广佣金）</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail6.jpg" style="width:100%"/>
            <div style="padding: 14px;text-align:center">
              <span>骑手配送（骑手佣金）</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail7.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>商超促销</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail8.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>代办服务</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
       <el-row style="margin-top:50px;z-index:15">
        <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail9.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>独立摄像</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail10.jpg" style="width:100%"/>
            <div style="padding: 14px;text-align:center">
              <span>自媒体服务</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail11.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>家政保洁</span>
            </div>
          </el-card>
        </el-col>
         <el-col :span="5" :offset="1">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <img src="https://xionghua.oss-cn-beijing.aliyuncs.com/index/img/product_detail12.jpg" style="width:100%"/>
            <div style="padding: 14px">
              <span>知识付费</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
     
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
import WOW from 'wowjs';
import { tryHideFullScreenLoading, showFullScreenLoading } from '../network/serviceHelp'
  export default {
  inject: ['reload'],
  data() {
    return {
     
    }
  },
  methods:{
      

  },
  created(){
   
  },
  mounted(){
    let wow = new WOW.WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
        });
        wow.init();
  }
  }
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>